<template>
  <section class="section" style="padding-top: 1.5rem;">
    <div class="container Nunito">
      <section class="section headerSection">
        <div class="container has-text-centered">
          <h1 class="title is-2 noselect">Our Donor's</h1>
          <h1 style="margin-bottom:0px" class="subtitle has-text-white is-5 noselect">Wanna become a supporter? Click the button below!</h1>
          <h1 style="margin-bottom:0px" class="subtitle has-text-white is-6 noselect">Don't see yourself? It can take up to 1hr to update!</h1>
          <h1 style="margin-bottom:5px" class="subtitle has-text-white is-6 noselect">All past and current donor show!</h1>
          <b-button icon-left="dollar-sign" type="is-info" tag="router-link" :to="{ path: '/premium', query: {ref: 'donors'} }">Donate $1/m</b-button>
        </div>
      </section>
      <section class="section" style="padding: 0;">
        <h3 v-if="donors <= 0" class="title is-5" style="text-align: center;font-style: italic;">Oh! Looks like we dont have any donors! Wanna become the first?</h3>
        <div class="columns is-multiline">
          <div class="column is-4" v-for="donor in donors" :key="donor.name">
            <div class="card has-shadow twitch-card">
              <div class="card-content" style="height: 100%;">
                <div class="media">
                  <div class="media-content" style="overflow-x: unset;">
                    <router-link v-if="donor.discord" tag="p" :to="{ path: `/profile/${donor.discord.id}` }" class="title is-4 twitch-streamer-name" :class="{'mainTextColor': false}">
                    {{ donor.discord ? (donor.discord.username || 'Someone') : (donor.name || 'Someone') }} <b-tag class="Nunito" style="margin-bottom: 2px;" v-if="donor.status !== 'active'" rounded type="is-danger">Expired</b-tag><b-tag class="Nunito" style="margin-bottom: 2px;" v-else rounded type="is-warning">{{ donor.membership_level ? premiumType(donor.membership_level.grace.premiumType) : 'Unknown' }}</b-tag>
                    </router-link>
                    <p v-else class="title is-4 twitch-streamer-name" :class="{'mainTextColor': false}">
                    {{ donor.name }} <b-tag class="Nunito" style="margin-bottom: 2px;" v-if="donor.status !== 'active'" rounded type="is-danger">Expired</b-tag><b-tag class="Nunito" style="margin-bottom: 2px;" v-else rounded type="is-warning">{{ donor.membership_level ? premiumType(donor.membership_level.grace.premiumType) : 'Unknown' }}</b-tag>
                    </p>
                    <p class="is-6 has-text-grey-light" v-if="donor.discord" style="margin-bottom: -5px;">Discord: @{{ donor.discord.username}}#{{donor.discord.discriminator }}</p>
                    <p class="is-6 has-text-grey-light" v-else style="margin-bottom: -5px;">Discord: Unknown User</p>
                    <p class="is-6 has-text-grey-light" style="margin-bottom: -5px;">Service: {{ donor.service.split('-')[0][0].toUpperCase() + donor.service.split('-')[0].substring(1) }}</p>
                    <p class="is-6 has-text-grey-light" style="margin-bottom: -5px;">Duration: {{ donor.duration_type.split('-').map(g => g[0].toUpperCase() + g.substring(1)).join(' ') }}</p>
                  </div>
                  <div class="media-right">
                    <figure class="image">
                      <router-link v-if="donor.discord" tag="img" :to="{ path: `/profile/${donor.discord.id}` }" :class="{'pulseAnimationRED': false}" :src="'https://cdn.discordapp.com/avatars/' + donor.discord.id + '/' + donor.discord.avatar + '.' + ((donor.discord.avatar || '').startsWith('a_') ? 'gif' : 'webp') + '?size=128'" class="to-twitch" :onerror="'this.src=\'' + serviceTypeImage(donor.service) + '\';'" :alt="false"></router-link>
                      <img v-else :class="{'pulseAnimationRED': false}" :src="serviceTypeImage(donor.service)" class="to-twitch" :onerror="'this.src=\'https://cdn.gracebot.net/grace/logo.png\';'" :alt="false" />
                    </figure>
                  </div>
                </div>
                <div class="content" style="flex: auto;" v-if="donor.message.visibility && donor.message.content">
                  <p class="title is-6" style="margin-bottom: 2px;">Shoutout Message:</p>
                  <p class="is-6 has-text-grey-light">{{ donor.message.content }}</p>
                  <!-- <p class="is-6 has-text-grey-light" v-else></p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section headerSection" style="margin-top: 100px;">
        <div class="container has-text-centered">
          <h1 class="title is-2 noselect">Or Support us one time!</h1>
          <h1 style="margin-bottom:5px" class="subtitle has-text-white is-5 noselect">Just want to support us without the perks?</h1>
          <b-button icon-left="dollar-sign" tag="a" type="is-info" href="https://www.buymeacoffee.com/GraceBot">Support One time!</b-button>
        </div>
      </section>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src

  export default {
    name: 'Donors',
    data () {
      return {
        donors: [],
        lastUpdated: null,
        chunkArray: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            const myChunk = myArray.slice(index, index + chunksize)
            tempArray.push(myChunk)
          }
          return tempArray
        },
        chunkArray2: function (myArray, chunksize) {
          var index = 0
          var arrayLength = myArray.length
          var tempArray = []
          for (index = 0; index < arrayLength; index += chunksize) {
            let myChunk = myArray.slice(index, index + chunksize)
            if (myChunk.length === 1) {
              const extra = { ...myArray.slice(index, index + chunksize)[0], sameas1: true }
              myChunk = [...myArray.slice(index, index + chunksize), extra]
            }
            tempArray.push(myChunk)
          }
          return tempArray
        }
      }
    },
    created () {
      this.start()
    },
    computed: {
    },
    methods: {
      premiumType (value) {
        if (Number(value) === 1) return 'Premium (User)'
        if (Number(value) === 2) return 'Premium +'
        if (Number(value) === 3) return 'Premium ++'
        if (Number(value) === 4) return 'Premium +++'
        return 'None'
      },
      serviceTypeImage (value) {
        if (value === 'buymeacoffee') return 'https://cdn.gracebot.net/bmcbrand/bmc-logo-yellow.png'
        if (value === 'patreon') return 'https://cdn.gracebot.net/patreon/logo-red.png'
        return 'https://cdn.gracebot.net/grace/logo.png'
      },
      start () {
        //  chunk: 3, sort: 'highest_premium', ...this.$route.query
        this.$store.dispatch('getApi', { path: 'system/donors', params: { sort: 'active_status,highest_premium', ...this.$route.query } }).then(async (result) => {
          this.donors = result.data.data.supports
          // .reduce((acc, current) => {
          //   const x = acc.find(item => item.discord && current.discord ? (item.discord.id === current.discord.id) : false)
          //   if (!x) {
          //     return acc.concat([current])
          //   } else {
          //     return acc
          //   }
          // }, [])
          console.log(result.data.data.supports)
          this.lastUpdated = result.data.data.lastUpdated
        }).catch((error) => {
          console.log(error, 'error')
          if (Number(error.response.status) === 500) return this.$router.push('/500?reason=' + error)
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
.has-shadow {
    box-shadow: 0 0.1rem 1.1rem rgba(0,0,0,.3)!important
  }
@media only screen and (max-width: 768px) {
  .media-right {
    display: none;
  }
}

.twitch-card {
  height: 100% !important;
  border-radius: 10px !important;
  background: var(--main-color-bg-2) !important;
}

.twitch-delete {
  float: right;
  margin-top: 10px;
  margin-right: 10px;
  visibility: hidden;
}

.twitch-card:hover .twitch-delete {
  visibility: visible !important;
}

.modal-close:hover::before, .delete:hover::before, .modal-close:hover::after, .delete:hover::after {
  background-color: #f04747 !important;
}

.to-twitch {
  cursor: pointer;
  transition: .3s;
  width: 80px;
  height: 80px;
  object-fit: contain;
  border-radius: 100px;
  border-color: var(--main-color-bg-3);
  border-width: 4px;
  border-radius: 100px;
  border-style: solid;
}

.to-twitch:hover {
  filter: brightness(.6);
  -webkit-filter: brightness(.6);
  transform: scale(1.02);
  transition: all .3s ease-in-out;
}

.twitch-streamer-name {
  margin-bottom: 0px;
  cursor:pointer;
  transition: .3s;
  width: fit-content;
}

.twitch-streamer-name:hover {
  color: #7957d5 !important;
}

.is-6.has-text-grey-light {
  width: fit-content;
}
  .headerSection {
    width: 100%;
    height: 260px;
    display: -webkit-box;
    display: -moz-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 30px;
    position: relative;
  }
</style>
